import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { Plan, ChatOption, UserExpert } from 'grommet-icons';

const Icons = ({ title, ...rest }) => {
  if (title === 'Daily Contact') {
    return <ChatOption {...rest} />;
  } else if (title === 'Personalized') {
    return <UserExpert {...rest} />;
  } else if (title === 'Interactive Schedule') {
    return <Plan {...rest} />;
  } else {
    return '';
  }
};

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.boundWindowHandler = this.handleWindowResize.bind(this);
    this.outerBoxRef = React.createRef();
    this.outerBoxHeight = 0;
  }

  calcBoxHeight() {
    return this.outerBoxRef.current.clientHeight;
  }

  componentDidMount() {
    window.addEventListener('resize', this.boundWindowHandler);

    const { onHeightChange } = this.props;
    // notify the initial box height
    if (onHeightChange) {
      this.outerBoxHeight = this.calcBoxHeight();
      onHeightChange(this.outerBoxHeight);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.boundWindowHandler);
  }

  componentDidUpdate() {
    // call hear because componenet updated,
    // need to check the boxHeight
    this.handleWindowResize();
  }

  handleWindowResize() {
    const currentHeight = this.calcBoxHeight();

    if (this.outerBoxHeight !== currentHeight) {
      if (this.props.onHeightChange) {
        this.outerBoxHeight = currentHeight;
        this.props.onHeightChange(currentHeight);

        //NOTE: don't need to store this in state here, just notify
        // a parent that can trigger a new render
      }
    }
  }

  getFeatureBoxWidth = size => {
    if (size === 'small') {
      return '90vw';
    } else if (size === 'medium') {
      return '95vw';
    } else {
      return '80vw';
    }
  };

  renderFeatures = (list, size) =>
    list.map((item, key) => (
      <Box
        key={key}
        pad={{ vertical: 'large', horizontal: 'small' }}
        // border="all"
        width={size === 'small' ? '100%' : '30%'}
        //width={{min: 'medium'}}
        margin="small"
        align="center"
        fill={size === 'small' ? null : 'vertical'}
        background={{ color: '#fff', opacity: 0.1 }}
        color="white"
      >
        <Heading
          level={4}
          size="small"
          HeadingAlign="center"
          weight="bold"
          margin={{ bottom: 'small' }}
          color="white"
          textAlign="center"
        >
          {item.title}
        </Heading>
        <Box margin="small">
          <Icons
            title={item.title}
            color="rgba(255,255,255, 0.7)"
            // color="brand"
            size="large"
          />
        </Box>
        <Paragraph color="white" textAlign="center">
          {item.text}
        </Paragraph>
      </Box>
    ));

  render() {
    const { list } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            ref={this.outerBoxRef}
            width={this.getFeatureBoxWidth(size)}
            background="accent-3"
            pad={{ vertical: 'large', horizontal: 'medium' }}
            direction={size === 'small' ? 'column' : 'row'}
            // align={ size === 'small' ? "center" : "start"}
            align="center"
            justify="center"
          >
            {this.renderFeatures(list, size)}
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

export default Features;

Features.propTypes = {
  list: PropTypes.array.isRequired,
  onHeightChange: PropTypes.func,
};
