import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Heading, Stack } from 'grommet';
import Layout from '../components/layout/layout';
import PreFooter from '../components/prefooter/PreFooter';
import FeatureBox from '../components/method/features';
import Markdown from '../components/markdown/markdown';


const Method = ({ data }) => {
  const { heroImage, heroText, featureList, content } = data.methodJson;

  const [featureHeight, setFeatureHeight] = useState(0);

  const featureHeightChanged = newHeight => {
    if (newHeight !== featureHeight) {
      // trigger new render with the new height
      setFeatureHeight(newHeight);
    }
  };

  return (
    <Layout pageTitle="Method">
      <Stack fill anchor="top">
        <Box height="75vh" fill="horizontal">
          <Img
            style={{ height: '100%', width: '100%' }}
            fluid={heroImage.childImageSharp.fluid}
          />
        </Box>
        <Box align="center" gap="medium" width="100vw" pad={{ top: '20vh' }}>
          <Heading size="small" color="white" textAlign="center">
            {heroText}
          </Heading>
          <FeatureBox
            list={featureList}
            onHeightChange={featureHeightChanged}
          />
        </Box>
      </Stack>
      <Box
        align="center"
        pad={{
          top: `calc(${featureHeight}px - 50vh + 60px)`,
          bottom: 'xlarge',
          horizontal: 'xlarge',
        }}
      >
        <Box width={{ max: '800px' }}>
          <Markdown >
            {content.childMarkdownRemark.rawMarkdownBody}
          </Markdown>
        </Box>
      </Box>

      <PreFooter buttonLabel="See Pricing" buttonLink="/pricing" />
    </Layout>
  );
};




export default Method;

Method.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query MethodPageQuery {
    methodJson {
      heroImage {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroText
      featureList {
        title
        text
      }
      content {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`;
